@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as *;
@use "../../../scss/main" as *;
@use "../../../scss/font" as *;

.wrapper {
  min-height: calc(240px - 60px + 160px); /* cover + avatar height */
  margin-bottom: 32px;
}

.mobile,
.desktop {
  position: relative;
}

.desktop {
  display: none;

  @include media-lg-and-up {
    display: block;
  }
}

.mobile {
  @include media-lg-and-up {
    display: none;
  }
}

.bg {
  width: 100%;
  object-fit: cover;
  height: 100%;
  min-height: 178px;

  @include media-md-and-up {
    min-height: 240px;
  }
}

.bar {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 24px;
  padding-left: 184px;
}

.bar2nd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 21px;
  width: 100%;
  margin-top: 48px;

  @include media-lg-and-up {
    margin-top: 36px;
  }
}

.avatar {
  height: 160px;
  width: 160px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 40px;
  border: 4px solid #fff;
  box-shadow: 1px 1px 1px 1px rgba(16, 24, 40, 0.08);
  position: absolute;
  left: 0;
  top: -60px;
}

.avatar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  object-position: center;
}

.fullname {
  @extend %text-heading-3;
  color: $colour-primary;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;

  @include media-lg-and-up {
    min-height: 100px;
  }
}

.subtitle {
  @extend %text-body-2;
  color: $colour-base-800;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  row-gap: 4px;

  .dot {
    width: 8px;
    height: 8px;
    background-color: $colour-secondary;
    display: block;
    border-radius: 50%;
  }
}

.location {
  @extend %text-body-2;
  color: $colour-base-800;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.bar2nd + .action {
  margin-top: 12px;
}

.action {
  button {
    width: 100%;
  }
}

.actions {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
}

.backBtn {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin: 24px 0;
  padding: 8px 14px;
  background-color: $colour-base-000;
  color: $colour-base-800;
  border-radius: 8px;
  border: 1px solid $colour-base-400;
  cursor: pointer;
  & > span {
    @extend %text-body-2;
  }

  svg {
    transform: rotate(180deg);
  }
}

.bgSkeleton {
  background-color: #efeff3;
  height:100%;
  height:303px
}

.actions {
  display: flex;
  align-items: center;
}

.action Button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6a3d; /* Kolor tła przycisku */
  color: #ffffff; /* Kolor tekstu */
  border-radius: 50px; /* Zaokrąglenie */
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  gap: 8px; /* Odstęp między ikoną a tekstem */
}

.btnContent {
  display: flex;
  align-items: center;
  gap: 8px; /* Odstęp między ikoną a tekstem */
}

.mailIcon {
  width: 16px;
  height: 16px;
  color: #ffffff; /* Kolor ikony */
}