@use "@styles/variables" as *;
@use "@styles/mixins" as *;
@use "@styles/font" as *;

.modal {
  .title,
  .titleThank,
  .titleError {
    margin-top: 60px;
    color: $colour-primary;
    @include media-lg-and-up {
      font-size: 28px !important;
      margin-top: 72px;
    }

    &Thank {
      margin-top: 40px;
    }
  }

  .info {
    @extend %text-body-1;
    color: $colour-base-800;
    margin-bottom: 20px;
  }

  .btn {
    @extend %text-button-md;
  }

  .wrapper {
    width: 100%;
    max-width: 580px;
    overflow: auto;
    scrollbar-width: none;

    .feedbackForm {
      padding: 2px;
      overflow: auto;
      scrollbar-width: none;
      & .fieldBox {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 32px;
        border: 0;
      }
      & .fieldTitle {
        @extend %text-button-md;
        color: $colour-primary;
        margin-bottom: 16px;
      }

      .radio {
        @extend %text-button-sm;
        position: relative;
        display: inline-block;
        display: flex;
        align-items: center;
        gap: 16px;
        color: $colour-base-800;

        & input {
          display: none;
        }

        & input + span {
          padding: 5px;
          box-shadow: 0 0 0 1px $colour-primary;
          width: 20px;
          height: 20px;
          border: 5px solid $colour-base-000;
          border-radius: 50px;
        }
        & input:checked + span {
          background-color: $colour-primary;
        }
      }

      .select {
        & svg {
          fill: $colour-primary;
        }
        & div {
          @extend %text-body-2;
          color: $colour-base-800;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .remark {
        @extend %text-body-2;
        padding: 14px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $colour-base-400;
        outline: none;
        color: $colour-base-600;
        resize: vertical;
      }
      .btnBox {
        display: flex;
        gap: 24px;
        margin-top: 48px;
        & button {
          @extend %text-button-md;
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
  }

  .imgBox {
    text-align: center;
    padding: 0 20px 20px 20px;
    & svg {
      max-width: 100%;
      height: unset;
    }
  }

  .loaderBox {
    position: fixed;
    top: 80px;
    bottom: 20px;
    left: 0;
    right: 0;
    background-color: rgba($colour-base-000, 0.5);
    z-index: 1001;
  }
}
