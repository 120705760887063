@use '../../../../scss/variables' as *;

.wrapper {
	display: inline-block;
	position: relative;
}

.button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: $gray-color;
	cursor: pointer;

	svg {
		width: 95%;
	}
}

.counter {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12px;
	height: 12px;
	font-size: 16px;
	font-weight: 600;
	color: $light-color;
	line-height: 1em;
	background-color: $success-color;
	border-radius: 50%;
}

.notifications {
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	width: 248px;
	padding: 20px 20px 14px 17px;
	border-radius: 8px;
	background-color: $light-color;
	transform: translateY(calc(100% + 10px)) translateX(-4px);
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.14));
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.34s;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid $light-color;
		transform: translateY(-100%) translateX(-8px);
	}

	&[data-is-visible='true'] {
		opacity: 1;
		visibility: visible;
	}
}

.close {
	align-self: flex-end;
	max-width: max-content;
	background-color: transparent;
	cursor: pointer;
}
