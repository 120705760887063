.Label {
    font-weight: 600;
}

.Checkboxes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}
.checkboxLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
}

.fieldLabelInputIntegration {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 180%;
    color: #252B61;
}