@use '../../../../../scss/variables' as *;
@use '../../../../../scss/mixins' as *;
@use '../../../../../scss/font' as *;

.wrapper {
  padding: 0;
  grid-column: 1/-1;
  border-radius: 15px;
  background-color: $light-color;

  .formSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 27px;

    > * {
      grid-column: span 2;
    }
  }

  .button {
    padding: 9px;
    margin-top: 19px;
    margin-bottom: 50px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .title {
    @extend %text-subtitle-2;
    color: $colour-primary;
    margin: 0px 0 32px;

    &Mt0 {
      margin-top: 0;
    }
  }

  .calendar {
    position: relative;
    overflow: auto;

    :global {
      .rbc-toolbar {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        min-width: 720px;
        overflow: hidden;

        .rbc-btn-group {
          display: flex;
          justify-content: space-between;
          width: 100%;

          button:first-of-type {
            display: none;
          }

          button {
            position: relative;
            color: transparent;
            background-color: transparent;
            border: none;
            width: 30px;
            padding: 25px 0;
            box-shadow: none !important;
            cursor: pointer;

            &:before {
              position: absolute;
              content: '';
              top: 50%;
              left: 50%;
              display: block;
              width: 12px;
              height: 12px;
              border-bottom: 2px solid #252b42;
              border-right: 2px solid #252b42;
            }

            &:first-of-type {
              display: none;
            }

            &:nth-of-type(2) {
              &:before {
                transform: translate(-50%, -50%) rotate(135deg);
              }
            }

            &:nth-of-type(3) {
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg);
              }
            }
          }
        }
      }

      .rbc-time-view {
        border-bottom: none;
        width: calc(100% - 60px);
        min-width: 660px;
        margin: 0 30px;
      }

      .rbc-today {
        background-color: transparent;
      }

      .rbc-time-content,
      .rbc-toolbar-label,
      .rbc-btn-group:nth-of-type(3),
      .rbc-time-header-gutter {
        display: none;
      }

      .rbc-day-bg + .rbc-day-bg {
        border-left: none;
      }

      .rbc-time-view,
      .rbc-header,
      .rbc-time-header-content {
        border-left: none;
        border-right: none;
      }

      .rbc-time-header-cell {
        min-height: max-content;
      }

      .rbc-header {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .rbc-allday-cell {
        position: relative;
        padding-top: 15px;
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
        @include hide-scrollbar;
      }
    }

    .header {
      text-align: center;

      span {
        display: block;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }

      small {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        color: $link-color;
      }
    }

    .day {
      position: relative;
      width: 100%;

      &::before {
        content: 'Brak terminów';
        display: block;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        line-height: 1em;
        letter-spacing: -0.02em;
        text-align: center;
        color: #a1a1a9;
        transform: translateY(200%);
      }
    }

    .hour {
      width: 100%;
      padding: 8px;
      background-color: #f5f5f5;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 7px;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.24s, color 0.24s;

      &:disabled {
        text-decoration: line-through;
        pointer-events: none;
      }

      &:hover,
      &[data-is-current='true'] {
        background-color: $primary-color;
        color: $light-color;
      }
    }
  }

  .textarea {
    grid-column: 1/-1 !important;

    textarea {
      min-height: 105px;
    }
  }

  .checkbox {
    display: block;
    margin-top: 30px;

    span {
      font-size: 12px;
    }
  }


  @media screen and (min-width: $md) {
    padding: 0;

    .formSection {
      gap: 20px 27px;

      > * {
        grid-column: span 1;
      }
    }
  }

  @media screen and (min-width: $lg) {
    grid-column: 1/2;
    grid-row: 1/3;

    .button {
      max-width: 295px;
    }
  }
}
