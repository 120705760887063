@use '../../../variables' as *;

.pitchdeck__progress-bar {
	margin-bottom: 32px;
	width: 100%;
	max-width: 768px;
	overflow-y: auto;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	.progress-bar {
		&__list {
			margin: 0 auto;
			width: max-content;
			padding: 8px 10px;
		}

		&__item {
			margin-right: 14px;
			min-width: max-content;

			&--choosed {
				.progress-bar__step {
					background-color: $primary-color;
					color: $light-color;
				}
			}

			&--disabled {
				.progress-bar__step {
					border: 1px solid $gray-color;
					color: $gray-color;
					opacity: 0.7;
				}
			}
		}

		&__step {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			margin-right: 14px;
			border: 1px solid $primary-color;
			border-radius: 50%;
			color: $primary-color;
		}
	}
}
