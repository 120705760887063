@use '../variables' as *;
@use '../mixins' as *;
@use 'sass:map';

.form {
  width: 100%;
  max-width: 576px;
  padding: 32px 25px 0;
  border-radius: 16px;
  box-shadow: 0 0px 12px rgba($dark-color, 0.2);
  background-color: $light-color;

  &__title {
    @include roboto;
    font-size: map-get($font-size, 'xl');
  }
  
  &__subtitle {
    margin: 12px 0;
    font-weight: 300;
    font-size: map-get($font-size, 'sm');
  }
  
  &__link {
    color: $primary-color;
    text-decoration: underline;
  }
  
  &__submit {
    width: 100%;
    font-size: map-get($font-size, 'sm');
    margin: 20px 0;
    padding: 16px;
  }
  
  &__cta {
    font-weight: 400;
    margin: 24px 0 12px;
  }
  
  &__container {
    padding-top: 20px;
    width: 100%;
  }
  
  &__github-btn {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid $dark-color;
    font-size: 16px;
    color: $dark-color;
  
    svg{
      width: 26px;
      height: 26px;
    }
  }
}
