@use "../../../scss/variables" as *;
@use "../../../scss/font" as *;
@use "../../../scss/mixins" as *;

.PaginationSkeleton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.mentorSection {
  padding: 32px 0;
  @media screen and (min-width: $xl) {
    padding: 40px 0;
  }
}
.userIdentity {
  padding: 0 !important;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.alert {
  display: flex;
  border: 1px solid $colour-error-100;
  color: $colour-error-100;
  background-color: $colour-error-200;
  border-radius: 12px;
  padding: 12px 16px;

  p {
    @extend %text-button-sm;
  }
}

.alert2 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: auto;
  flex-direction: column;
  padding: 40px 48px 48px;

  .icon {
    width: 48px;
    height: 48px;
    background-color: #ffe7e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      font-size: 18px;
      color: $colour-secondary;
    }
  }

  p {
    @extend %text-button-lg;
    color: $colour-base-800;
    max-width: 352px;
    margin: auto;
  }
}

.service {
  text-transform: capitalize;
}

.isPro {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;

  svg {
    color: $colour-secondary;
    font-size: 20px;
  }
}

.dotsCell {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableBtn {
  @extend %text-reset-button, %text-button-md;
  color: $colour-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #e2e9fc;
  border-radius: 8px;
  text-wrap: nowrap;
  margin-right: 16px;

  &:hover {
    background-color: #fff;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.studentMentorSlickItem {
  padding-right: 32px;
  height: 100%;

  .studentMentorCard {
    display: flex;
    padding: 32px 24px;
    gap: 24px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $colour-base-200;
    min-height: 316px;
    height: 100%;
  }

  .profileLink {
    @extend %text-button-md;
    color: $colour-secondary;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    text-decoration: none;
    text-align: left;

    svg {
      font-size: 24px;
    }
  }

  .userIdentity {
    padding: 0;
    margin-bottom: 16px;
  }

  .hr {
    border-top: 1px solid $colour-base-200;
    width: 100%;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    a {
      text-decoration: none;
    }
  }

  .btn {
    @extend %text-button-md;
    color: $colour-base-800;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid $colour-base-200;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: $colour-base-200;
    }
    &:disabled {
      opacity: 0.6;
      background-color: #f5f5f5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .btnRed {
    color: $colour-error-100;
  }

  .cardStatus {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    flex-direction: column;

    p {
      @extend %text-caption;
      color: $colour-base-800;
      min-height: 44px;
    }
  }
}

.slick {
  :global(.slick-list) {
    padding-bottom: 18px;
  }
  :global(.slick-track) {
    display: flex !important;
  }
  :global(.slick-slide) {
    display: flex !important;
    height: auto;
    align-items: stretch;
    justify-content: center;
  }
  :global(.slick-slide) > div {
    width: 100%;
    max-width: 400px;
  }
}
.sectionContainer {
  max-width: calc(1200px + 3rem);
  margin: auto;
}
.currentPlan {
  display: flex;
  flex-direction: column;
  margin: 32px 16px 0;
  gap: 40px;

  @include media-md-and-up {
    flex-direction: row;
    justify-content: space-between;
  }

  @include media-xl-and-up {
    margin: 40px 24px;
  }
  .titleBox {
    @include media-md-and-up {
      width: 45%;
    }
  }
  .title {
    @extend %text-heading-2;
    margin-bottom: 16px;
    font-size: 28px !important;
    color: $colour-primary;
    @include media-xl-and-up {
      font-size: 32px !important;
    }
  }

  .subtitle {
    @extend %text-button-md;
    color: $colour-base-800;
    &Link {
      color: $colour-secondary;
      text-decoration: none;
      font-weight: 600;
    }
  }

  &InfoBox {
    flex-grow: 1;
    display: flex;
    gap: 16px;
    flex-direction: column;
    max-width: 480px;
    padding: 32px;
    border: 1px solid $colour-base-400;
    border-radius: 20px;

    .planHeader {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 12px;

      .iconBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border: 1px solid $colour-base-400;
        border-radius: 50%;
        background-color: $colour-base-200;
      }
      .planName {
        @extend %text-button-lg;
        color: $colour-primary;
      }
    }
    .planInfoList {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .planItem {
      @extend %text-button-sm;
      color: $colour-primary;
      &Value {
        @extend %text-caption;
        color: $colour-base-800;
        font-weight: 500;
      }
    }
  }
}
.sectionPricing {
  margin-top: 0px !important;
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
    width: 100%;

    @include media-md-and-up {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include media-xl-and-up {
      flex-wrap: nowrap;
    }
  }
}

.tooltipContainer {
  position: relative;
  display: inline-block;

  .btnDisabeldBox:hover + .tooltip {
    display: block;
    opacity: 1;
  }
}
.tooltip {
  display: none;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $colour-base-800;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: $colour-base-800 transparent transparent transparent;
  }
}

.subscriptionHistoryContainer {
  margin: 40px 20px;
}

.modal {
  max-width: 680px !important;
  margin: 0 12px;

  .title {
    @extend %text-heading-3;
    margin-top: 36px;
    .modalPlan {
      color: $colour-secondary;
    }
  }
  .info {
    @extend %text-body-2;
    margin-bottom: 32px;
    color: $colour-base-600;
  }

  &BtnBox {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 24px;

    @include media-md-and-up {
      flex-direction: row;
      justify-content: stretch;
    }
    & button {
      @extend %text-button-md;
      @include media-md-and-up {
        width: calc(50% - 12px);
      }
    }

    & .transparentBtn {
      color: $colour-base-800;
      background-color: $colour-base-200;
    }

    & .mainBtn {
      background-color: $colour-error-200;
      color: $colour-error-100;
    }
  }
}