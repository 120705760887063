@use '../../../../../scss/variables' as *;
@use '../../../../../scss/font' as *;

.box {
    align-items: flex-start;
    border-radius: $box-border-radius;
    border: $box-border;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;
    padding: 16px;
    background-color: #fff;

    h3 {
        @extend %text-button-lg;
        color: $colour-primary;
    }

    ul {
        align-items: flex-start;
        display: flex;
        gap: 16px;
        justify-content: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        @extend %text-button-sm;
        align-items: center;
        background-color: rgba($colour-secondary, 0.09);
        border-radius: 15px;
        border: 1px solid $colour-secondary;
        color: $colour-secondary;
        display: flex;
        justify-content: center;
        padding: 4px 16px;
    }
}

.timeZone {
    @extend %text-button-sm;
    margin-top: 12px;
    font-size: 12px;
    color: #56658F;

    span {
        font-weight: 600;
        line-height: 21.6px;
        font-family: 'Montserrat', sans-serif;

        strong {
            color: $colour-primary;
            font-weight: bold;
        }
    }
}
