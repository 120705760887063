@use "@styles/variables" as *;
@use "@styles/mixins" as *;
@use "@styles/font" as *;

.pricingSection {
  padding: 44px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricingCard {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $colour-base-000;
  border: 1px solid $colour-base-400;
  border-radius: 12px;
  padding: 32px 24px;
  text-align: left;
  width: 100%;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;

  @include media-md-and-up {
    width: calc(50% - 30px);
    min-height: 100%;
  }

  &:hover {
    background-color: $colour-base-200;
  }

  &.selected {
    border-color: $colour-secondary;
    border-radius: 24px;

    &:hover {
      background-color: $colour-base-000;
    }
  }

  &.inactive {
    opacity: 0.5; // Wyszarzamy nieaktywny plan
    cursor: not-allowed; // Kursor "nie dozwolony"
    pointer-events: none; // Wyłącz możliwość klikania

    .btn {
      background-color: $colour-base-400;
      border-color: $colour-base-600;
      color: $colour-base-800;
    }

    &:hover {
      background-color: $colour-base-000;
    }
  }

  .title {
    line-height: 1.2;
    font-size: 24px;
    font-weight: 700;
    color: $colour-primary;
    @media (min-width: 992px) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  .price {
    font-size: 29px;
    font-weight: 700;
    color: $colour-secondary;
  }

  .planHeader {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 12px;

    .iconBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 1px solid $colour-base-400;
      border-radius: 50%;
      background-color: $colour-base-200;
    }
  }

  .priceDetails {
    font-size: 14px;
    font-weight: 600;
    color: $colour-base-800;
  }

  .planSubtitle {
    font-size: 14px;
    font-weight: 600;
    color: $colour-primary;
    margin-bottom: 8px;
    @include media-md-and-up {
      font-size: 16px;
    }
  }

  .list {
    list-style: none;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @include media-md-and-up {
      flex-grow: 1;
    }

    .listItem {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .value {
      font-size: 14px;
      font-weight: 500;
      color: $colour-base-800;
      display: flex;
      align-items: center;
    }

    .icon {
      color: $colour-primary;
    }
  }

  .btn {
    font-size: 16px;
    font-weight: 600;
    background-color: $colour-base-000;
    color: $colour-primary;
    padding: 12px;
    border-radius: 24px;
    border: 2px solid $colour-primary;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover,
    &Selected {
      color: $colour-base-000;
      background-color: $colour-primary;
    }

    &.btnInactive {
      background-color: $colour-base-400;
      color: $colour-base-800;
      border-color: $colour-base-600;
      cursor: not-allowed;
      pointer-events: none; // Wyłączenie kliknięcia
    }
  }
}
