@use "../../../../../../scss/variables" as *;
@use "../../../../../../scss/mixins" as *;
@use "../../../../../../scss/main" as *;
@use "../../../../../../scss/font" as *;

.linksSection {
  display: flex;
  flex-direction: column;
  position: relative; /* For positioning the header */
  padding: 16px;
  border: 1px solid $colour-base-400; /* Add the border */
  border-radius: 12px; /* Rounded corners */
  background-color: #fff; /* White background */
  margin-top: 16px;
}

.linksTitle {
  position: absolute; /* Keeps it in the top-left corner */
  top: 16px; /* Adjust spacing from the top */
  left: 16px; /* Adjust spacing from the left */
  margin: 0; /* Remove default margins */
  font-size: 16px; /* Set font size */
  font-weight: 600; /* Set font weight */
  line-height: 1.8; /* 180% line height */
  color: $colour-primary; /* Match the color to the design */
}

.noEbookWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 48px; /* Ensure space from the header */
  gap: 16px;
}

.noProductMessage {
  font-size: 0.875rem; /* Adjust font size */
  color: $colour-base-600; /* Softer color for the message */
}