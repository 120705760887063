@use '../../variables' as *;

.messages-view {
	display: grid;
	grid-template-columns: 1.5fr 3fr;
  gap: 24px;

	> * {
		grid-column: 1/-1;
	}

	@media screen and (min-width: $lg) {
		> * {
			grid-column: span 1;
		}
	}
}
