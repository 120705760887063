@use '../../../../../scss/variables' as *;
@use '../../../../../scss/font' as *;

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.button {
    @extend %text-button-md;

    &:disabled {
        background-color: $new-gray-color;
        color: white;
    }
}

.checkbox label span {
    @extend %text-button-sm; 
    color: $colour-base-800;
}
.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}