@use '@styles/variables' as *;
@use '../../../../scss/font' as *;

.topbar {
  width: 100%;
  height: $layout-topbar-height;
  top: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  @media screen and (min-width: $sm) {
    padding-left: calc($layout-quick-sidebar-width + 12px);
    justify-content: flex-end;
  }

  .left {
    display: block;

    @media screen and (min-width: $sm) {
      display: none;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    @media screen and (min-width: $sm) {
      margin-right: 7vw;
    }


    > * {
      display: none;

      @media screen and (min-width: $xs) {
        display: flex;
      }
    }

    > .hamRight {
      display: flex;
    }

    .hamRight {
      @media screen and (min-width: $sm) {
        display: none;
      }
    }

    .email {
      display: none;

      @media screen and (min-width: $sm) {
        display: block;
      }
    }
  }
}

.email {
  color: $colour-primary;
}

.profile {
  @extend %text-reset-button;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  &Image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
}

.navbarLogo {
  position: relative;
  top: 4px;
}
