@use '../../variables' as *;
@use '../../mixins' as *;
@use 'sass:map';

.join {
	position: relative;
	z-index: 0;
	background-image: linear-gradient(
		135deg,
		$primary-color 35%,
		$secondary-color
	);

	&:before {
		@include pseudo-element;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-image: url('../../../assets/img/network.png');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.container {
		min-height: 100vh;
		padding-top: 45px;
		padding-bottom: 45px;
	}
}
