@use '@styles/variables' as *;

.wrapper {
	position: relative;

	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		background-color: transparent;
		cursor: pointer;
	}

	.options {
		position: absolute;
		bottom: 0;
		right: 0;
		min-width: max-content;
		width: 160px;
		border-radius: 8px;
		overflow: hidden;
		filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.14));
		transform: translateY(100%);

		&Item {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			min-width: max-content;
			width: 100%;
			padding: 12px 16px;
			background-color: $colour-base-000; // Updated color
			font-size: 12px;
			font-weight: $font-weight-medium;
			line-height: 18px;
			cursor: pointer;
			transition: background-color 0.24s;

			&:hover {
				background-color: $colour-base-400; // Updated color
			}

			&:not(:last-child) {
				border-bottom: 1px dashed $colour-base-400; // Updated color
			}

			&.disabled {
				cursor: not-allowed;
				opacity: 0.5;
				pointer-events: none; // Disable all pointer events
			}
		}
	}
}

$danger-color: $colour-error-100; // Added for potential usage
