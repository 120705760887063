@use '../../variables' as *;
@use 'sass:map' as *;

.session-card {
	padding: 20px 15px;
	border: 1px solid rgba($gray-color, 0.65);
	border-radius: 7px;

	&__title {
		font-size: 20px;
	}

	&__description {
		font-size: 14px;
		margin: 1.2em 0 2em;
	}

	&__details {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	&__price {
		font-size: 18px;

		small {
			font-size: 0.8em;
		}
	}

	@media screen and (min-width: map-get($breakpoints, 'md')) {
		&__title,
		&__price {
			font-size: 22px;
		}
	}
}
