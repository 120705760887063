@use "../../../../../scss/variables" as *;
@use "../../../../../scss/mixins" as *;
@use "../../../../../scss/main" as *;
@use "../../../../../scss/font" as *;

.MentorMainWrapper {
    display: flex;
    gap: 48px;
    flex-direction: column;


    aside {
        flex: 458;
        max-width: 458px;
    }

    main {
        flex: 566;
    }

    @include media-xl-and-up {
        flex-direction: row;
        margin: 48px 0;
    }
}

.MentorContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius:12px;
    @include media-lg-and-up {
        padding: 24px;
        border: 1px solid $colour-base-400;
    }
}

.desktop.linksSection {
    margin-top: 32px;
    padding: 24px;
}

.linksSection {
    margin: 12px 0 0;
}

.border {
    border-radius: 12px;
    border: 1px solid $colour-base-400;
}

.content_title {
    @extend %text-subtitle-1;
    color: $colour-primary;
}

.content_content {
    @extend %text-caption;
    color: $colour-base-600;
}

.showMore {
    @extend %text-reset-button, %text-button-sm;
    color: $colour-secondary;
    margin-top: 18px;
    cursor: pointer;
}

.skills {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.skillsTitle {
    @extend %text-button-lg;
    color: $colour-primary;
}

.skillsTags {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.skillsTag {
    @extend %text-button-sm;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    height: 38px;

    &.skillsTagShowMore {
        cursor: pointer;
    }

    &.skillsTagPrimary {
        background-color: $colour-base-200;
        color: $colour-base-800;

        &.skillsTagShowMore {
            background-color: $colour-base-400;
        }
    }

    &.skillsTagSecondary {
    // background-color: $colour-base-800;
    // color: $colour-base-200;
    // background-color: rgba($colour-secondary, 0.10); //$colour-base-600;
    // color: $colour-secondary;
    background-color: $colour-base-600;
    color: $colour-base-400;

    &.skillsTagShowMore {
        background-color: $colour-base-800;
    }
    }
}

.servicesTags {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.servicesTag {
    @extend %text-caption;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 22px;
    margin-left:8px;
    width: 100%;
    color: $colour-primary;
    @include media-sm-and-up {
        width: fit-content;
        margin-left:0;
    }
}

.servicesTag svg {
    color: $colour-secondary;
    height: 20px;
    width: 20px;
}

.linksSection {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
}

.linksTitle {
    @extend %text-button-lg;
    color: $colour-primary;
}

.socialIcons {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.socialIcon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $colour-base-400;
    transition: color 0.25s ease-in-out;

    &:hover {
        color: $colour-base-600;
    }
}

.socialIcon:hover {
    background-color: $colour-base-600;
}

.socialIcon svg {
    width: 32px; //13.17px;
    height: 32px; //13.17px;
}
