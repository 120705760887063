@use '../../variables' as *;

.user-photo {
	position: relative;
	width: 52px;
	height: 52px;
	border-radius: 50%;
	box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 14px;
	background-color: rgba($gray-color, .25);

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		bottom: 0;
		right: 0;
		display: block;
		width: 25%;
		height: 25%;
		border-radius: 50%;
		border: 2px solid $light-color;
		box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 14px;
	}

	&[data-status='online'] {
		&::before {
			background-color: $success-color;
		}
	}
	&[data-status='offline'] {
		&::before {
			background-color: $gray-color;
		}
	}
	&[data-status='bussy'] {
		&::before {
			background-color: $danger-color;
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
}
