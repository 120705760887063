@use '../../variables' as *;
@use 'sass:map';

.user-profile {
	&__grid {
		position: relative;
		display: grid;
		width: 100%;
		grid-template-columns: 4fr 3fr;
		grid-template-rows: auto auto auto;
		gap: 30px;
		margin-top: 32px;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			display: block;
			width: calc(100% + 32px);
			height: 220px;
			top: -56px;
			left: -16px;
			background-color: darken($primary-color, 5%);
		}
	}

	&__data,
	&__data-info,
	&__about,
	&__price {
		grid-column: 1/-1;
	}

	&__data {
		grid-row: 1/2;

		&-photo {
			position: relative;
			width: 200px;
			height: 200px;
			margin-right: 25px;
			border-radius: 50%;
			border: 5px solid #fff;
			overflow: hidden;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				transform: translate(-50%, -50%);
			}
		}

		&-info {
			padding: 10px 0;

			.info {
				&__name {
					font-size: 26px;
					letter-spacing: 0.2px;
				}
				&__position {
					font-size: 16px;
					font-weight: 400;
					margin: 10px 0;
				}
				&__description {
					font-size: 16px;
					color: $primary-color;
					max-width: 516px;
				}
			}
		}
	}

	&__about {
		grid-row: 4/5;
		border-top: 1px solid rgba($gray-color, 0.5);
		margin-top: 20px;
	}

	@media screen and (min-width: map-get($breakpoints, 'md')) {

		&__data,
		&__data-info {
			grid-column: 1/2;
		}
		&__about {
			grid-row: 3/4;
		}
		&__price {
			grid-column: 2/3;
			grid-row: 1/-1;
		}
	}
}
