@use "@styles/variables" as *;
@use "@styles/font" as *;

$sidebar-bg: $colour-primary;
$sidebar-text: $colour-base-200;

.fullSidebar {
  --transition-base: 0.3s;

  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  //justify-content: space-between;
  background-color: $sidebar-bg;
  z-index: 30;
  transition: var(--transition-base);
  left: 0;
  top: 0;
  max-width: $layout-quick-sidebar-width;

  .logo {
    height: $layout-topbar-height;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0 24px;
    cursor: pointer;
    flex-shrink: 0;
  }

  .scrollContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      width: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $colour-base-800;
      box-shadow: inset 0 0 0.5em $colour-base-800;
      border-radius: 4px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    //overflow: hidden;
    padding: 24px 12px;
    transition: var(--transition-base);
  }

  .btn {
    display: grid;
    grid-template-columns: 24px 1fr;
    width: 100%;
    gap: 12px;
    padding-left: 17px;
    transition: calc(var(--transition-base) * 0.5);
    flex-shrink: 0;

    span {
      text-align: left;
      font-size: 14px;
    }
  }

  @media screen and (max-width: $sm - 1) {
    transform: translateX(-100%);
  }

  &.fullSidebarOpen {
    left: 0;
    max-width: $layout-full-sidebar-width;

    .list {
      padding: 24px;
    }

    .btn {
      padding-left: 8px;
    }

    @media screen and (max-width: $sm - 1) {
      transform: translateX(0);
      width: 100vw;
      max-width: unset;
    }
  }
}

.arrow {
  cursor: pointer;
  position: absolute;
  background: $sidebar-bg;
  border: 1px solid $colour-base-600;
  height: 24px;
  width: 24px;
  padding: 5px;
  border-radius: 100%;
  top: $layout-topbar-height;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition-base);
  transition-timing-function: ease-out;
  transition-delay: opacity var(--transition-base);

  transform: translate(-50%, -50%);
  opacity: 0;

  svg {
    transform: rotate(90deg);
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: $sm - 1) {
    display: none;
  }
}

.fullSidebar:hover {
  .arrow {
    opacity: 1;
    transform: translate(50%, -50%);
    transition-delay: 0;
  }
}

.tooltip {
  background-color: $sidebar-bg !important;
  padding: 10px !important;
}

.tooltipArrow {
  color: $sidebar-bg !important;
}

.hamburger {
  opacity: 0;
  transform: translateY(-300%);
  transition: var(--transition-base);
  pointer-events: none;

  span {
    background-color: $sidebar-text;
  }
}

.fullSidebarOpen {
  .hamburger {
    pointer-events: auto;
    transform: translateX(0);
    opacity: 1;
  }

  .arrow {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.btn {
  cursor: pointer;
  align-items: center;
  background-color: $sidebar-bg;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  width: calc($layout-quick-sidebar-width - 8px);

  &[data-is-current="true"] {
    background-color: $colour-secondary;

    &:hover {
      background-color: $colour-secondary;
      cursor: default;
    }
  }

  &:hover {
    background-color: lighten($sidebar-bg, 10%);
  }

  span {
    color: $sidebar-text;
    font-size: 10px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  svg {
    color: $sidebar-text;
    height: 24px;
    width: 24px;
  }
}

.btnBox {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  gap: 12px;
  border-top: 1px solid rgba($colour-base-000, 0.1);

  .btn {
    padding: 10px 8px 10px 17px !important;
    text-align: center;
    border-radius: 4px;
  }

  .btnLogin {
    @extend %text-button-md;

    text-align: center;
    border-radius: 100px;
    display: block;

    span {
      text-align: center;
    }
  }

  .btnRegister {
    background: #ffffff08;
    color: $colour-secondary;
    border-radius: 100px;
  }
}
