@use '../../variables' as *;
@use '../../mixins' as *;
@use 'sass:map';

.header {
	position: relative;
	z-index: 0;
	background-color: $primary-color;
	text-align: center;
	color: $light-color;

	.container {
		padding-top: 32px;
		padding-bottom: 96px;
	}

	&__title {
		@include roboto;
		font-size: map-get($font-size, 'xxl');
		line-height: 1.3em;
		letter-spacing: 0.4px;
	}

	&__text {
		max-width: 320px;
		margin: 24px auto 0;
		font-size: map-get($font-size, 'xs');
		font-weight: 300;
	}

	@media screen and (min-width: map-get($breakpoints, 'xs')) {
		.container {
			padding-top: 46px;
			padding-bottom: 102px;
		}

		&__title {
			font-size: map-get($font-size, 'xxxl');
		}

		&__text {
			max-width: 380px;
			font-size: map-get($font-size, 'sm');
		}
	}

	@media screen and (min-width: map-get($breakpoints, 'sm')) {
		.container {
			padding-top: 64px;
			padding-bottom: 136px;
		}

		&__title {
			font-size: 48px;
		}
	}

	@media screen and (min-width: map-get($breakpoints, 'md')) {
		background-image: url('../../../assets/img/network.png');
		background-position: 0 85%;
		background-size: cover;
		background-repeat: no-repeat;

		.container {
			padding-top: 72px;
		}

		&__title {
			font-size: 56px;
		}

		&__text {
			margin-top: 32px;
		}
	}

	@media screen and (min-width: map-get($breakpoints, 'lg')) {
		.container {
			padding-top: 64px;
			padding-bottom: 184px;
		}

		&__title {
			font-size: 64px;
		}

		&__text {
			margin-top: 44px;
		}
	}

	@media screen and (min-width: map-get($breakpoints, 'xl')) {
		.container {
			padding-top: 92px;
			padding-bottom: 204px;
		}

		&__title {
			font-size: 88px;
		}

		&__text {
			max-width: 460px;
			margin-top: 56px;
			font-size: map-get($font-size, 'md');
		}
	}
}
