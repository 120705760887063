.linksSection {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #e2e9fc;  // stała ramka
  border-radius: 12px;
  background-color: #fff;
  margin-top: 16px;
}
.newsletterContainer {
  width: 100%;
  max-width: 100%; // identyczna wartość co w "Produkty"
  //margin: 16px auto; // aby wycentrować, jeśli tak jest w "Produkty"
  //border: 1px solid #e2e9fc;
  //border-radius: 12px;
  //background-color: #fff;
  position: relative;
  padding: 16px;
  padding-top: 20px;
  padding-bottom: 15px;
}

.newsletterTitle {
  //position: absolute;
  top: 16px;
  left: 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.8;
  color: #252B61;
}

.popupTitle {
  //margin-left: 4px;
  font-size: 14px;
  color: #56658F;
}

.newsletterDescription {
  margin: 0 0 8px 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: #56658F;
  letter: 0px;
}

.newsletterForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Checkbox i label w jednej linii */
.checkboxContainer {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.checkboxLabel {
  margin-left: 6px;
  font-size: 14px;
  color: #56658F;
}

.regulationLink {
  color: #FF6A3D;
  cursor: pointer;
  text-decoration: underline;
}

.labelAndError {
  display: flex;
  flex-direction: column;
}

.error {
  margin-top: 6px; // lub inna wartość
  font-size: 12px;
  color: red;
}

.newsletterButton {
  background-color: #FF6A3D;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
}

.container {
  text-align: center;

  .lottieWrapper {
    width: 70%;
    max-width: 400px;
    margin: 0 auto;
  }

  .description {
    font-size: 14px;
    margin: 5px 0 0;
    color: #56658F;
  }
}


