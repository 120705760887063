@use '../../variables' as *;

.book-form {
	display: grid;
	width: 100%;
	grid-template-columns: 50% 1fr;
	gap: 60px;

	> * {
		grid-column: 1/-1;
	}

	&__title {
		font-size: 20px;
		margin-bottom: 1em;
	}

	&__text {
		display: block;
		font-size: 14px;
		margin-bottom: 1em;
	}

	&__group {
		padding: 25px 0 30px;

		textarea {
			width: 100%;
		}
	}

	&__days {
		display: flex;
		gap: 14px;
		padding: 15px 0 25px;
		overflow-y: hidden;
		overflow-x: scroll;
		list-style: none;
	}

	&__hours {
		display: none;

		&[data-visible='true'] {
			display: flex;
			flex-wrap: wrap;
			gap: 14px;
			padding-top: 25px;

			> * {
				flex: 1 1 52px;
				label {
					width: 100%;
				}
			}
		}
	}

	&__submit {
		&-container {
			border: 1px solid rgba($gray-color, 0.5);
			padding: 20px;
			border-radius: 15px;

      .custom-btn {
        display: block;
        width: 100%;
        margin-top: 56px;
      }

      .submit-container {
				&__mentor {
					display: grid;
					grid-template-columns: 54px auto;
					gap: 0 24px;
					margin-bottom: 36px;

					&-image {
						grid-column: 1/2;
						grid-row: 1/4;
						width: 54px;
						height: 54px;
						border-radius: 10px;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					&-title {
						font-size: 18px;
						line-height: 1em;
						margin-bottom: 0.25em;
					}

					&-subtitle {
						font-size: 14px;
						font-weight: 400;
					}
				}

				&__info {
					&-item {
						display: flex;
						justify-content: space-between;
						font-weight: 700;
						font-size: 14px;
						margin-bottom: 1.5em;
						span {
							&:first-of-type {
								color: rgba($gray-color, 0.95);
							}
							&:last-of-type {
								color: $dark-color;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: $md) {
		> * {
			grid-column: span 1;
		}
		&__title {
			font-size: 24px;
		}

		&__text {
			font-size: 16px;
		}
	}
}
