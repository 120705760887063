@use "@styles/variables" as *;
@use "@styles/mixins" as *;
@use "@styles/font" as *;

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  @extend %text-body-2;
  font-weight: 500;
  background-color: $colour-base-200;
  line-height: 40px;
  width: 100%;
  border-radius: 50px;
  padding-left: 52px;
  color: $colour-base-800;
  border: 1px solid transparent;
}

.input:focus,
.input:active {
  border-color: $colour-base-400;
  outline: none;
}

.icon {
  position: absolute;
  top: 10px;
  left: 16px;
  width: 20px;
  height: 20px;
  color: $colour-base-600;
  fill: $colour-base-600;
}

.ghost {
  visibility: hidden;
}
