@use "../../scss/variables" as *;

.container {
  width: 100%;
  padding: 0 1.5rem;
  margin: 0 auto;

  &[data-type="default"] {
    max-width: calc($xl + 3rem);
  }

  &[data-type="fluid"] {
    max-width: 100%;
  }
}
