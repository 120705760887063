.wrapper {
	padding: 4px 10px;
	border-radius: 50px;
	background: #eff4f9;
	text-align: center;
	white-space: nowrap;
  transition: opacity .24s;
  
  button{
    padding: 0 5px;
    font-weight: 700;
    background-color: transparent;
    cursor: pointer;
  }

  &:has(button:hover){
    opacity: .75;
  }
}
