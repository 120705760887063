@use '../../variables' as *;

.session-details {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 40px 60px;
	justify-items: center;
	padding-top: 40px;

	> * {
		grid-column: 1/-1;
	}

	&__title {
		font-size: 24px;
		margin-bottom: 0.75em;

		a {
			text-decoration: underline;
		}
	}

	&__info {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin-bottom: 20px;

		&-price {
			display: block;
			font-size: 20px;
			font-weight: 700;
			padding-right: 20px;
			border-right: 1px solid $gray-color;
		}

		&-country {
			display: flex;
			align-items: center;
			padding-left: 20px;
			border-left: 1px solid $gray-color;
		}
	}

	&__benefits {
		margin: 32px 0;
		display: flex;
		flex-wrap: wrap;
		gap: 14px;

		& > * {
			flex: 1 0 240px;
		}

		&-item {
			padding: 20px;
			border-radius: 8px;
			border: 1px solid $gray-color;
		}
	}

	&__content {
		.custom-btn {
			display: block;
			width: 100%;
			font-size: 16px;
		}
	}

	&__image {
		justify-content: center;
		border-radius: 15px;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	&__buttons{
		display: flex;
		gap: 24px;
		flex-wrap: wrap;
	}

	&__button {
		flex: 1 0 100px;
		text-align: center;
	}

	@media screen and (min-width: $md) {
		padding-top: 60px;

		&__content {
			grid-column: 1/2;
			grid-row: 1/2;
		}

		&__image {
			position: relative;
			grid-column: 2/3;
			grid-row: 1/2;
			width: 100%;
			height: 100%;
			max-height: $sm;
			max-width: $xs;

			img {
				position: absolute;
				display: block;

				top: 50%;
				left: 50%;
				height: 100%;
				width: 100%;
				object-fit: cover;
				transform: translate(-50%, -50%);
			}
		}
	}
}
