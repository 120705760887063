@use "../../scss/variables" as *;
@use "../../scss/font" as *;

.title {
	@extend %text-subtitle-2;
	color: $colour-primary;
	margin: 0 0 32px;
}

.wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 16px;

	> aside {
		flex: 300;
		order: 1;
		display: flex;
		flex-direction: column;
		gap: 24px;
		background-color: $colour-base-200;
		padding: 24px 20px 32px;
		margin: 0 16px;
	}

	> .main {
		flex: 725;
		order: 2;
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 16px 16px 64px;
		overflow: hidden;
		width: 100%;

		> section {
			display: flex;
			flex-direction: column;
			gap: 48px;
		}
	}

	@media screen and (min-width: $xl) {
		flex-direction: row;
		gap: 29px;
		padding: 43px 16px 72px;

		> aside {
			order: 2;
			padding: 0;
			background-color: transparent;
			margin: 0;
		}

		> .main {
			order: 1;
			padding: 0;
		}
	}

	.formWrapper {
		@extend %box-border;
		padding: 32px 24px;
	}
}