@use "../../../../../scss/variables" as *;
@use "../../../../../scss/mixins" as *;
@use "../../../../../scss/main" as *;
@use "../../../../../scss/font" as *;

.Inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.InputsCols2 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > * {
        flex: 1;
    }

    @include media-lg-and-up {
        flex-direction: row;
        gap: 24px;
    }
}

.Divider {
    width: 100%;
    position: relative;
    left: 0;
    border-top: 1px solid $colour-base-400;
    border-width: 1px;
    border-bottom: none;
    margin: 24px 0;

    @include media-lg-and-up {
        width: calc(100% + 48px);
        left: -24px;
    }
}

.Title {
    margin-bottom: 24px;
    display: block;
}

.Switchers {
    .Field {
        padding: 16px 16px 8px; 

        label {
            margin-left: 0;
            margin-right: 0;
            font-weight: 600;
        }  
    
        &:nth-child(odd) {
            background: $colour-base-200;
        }
    }
}

.EditBtn {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($colour-secondary, 0.1);
    color: $colour-secondary;
    border-radius: 50%;
    cursor: pointer;
}

.PopupInput {
    @extend %text-button-md;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    color:$colour-primary;
}

.Footer {
    margin: 44px 0 145px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .Btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
            width: 18px;
            height: 18px;
            font-weight: 600;
        }
    }
}
