@use "@styles/variables" as *;
@use "@styles/mixins" as *;
@use "@styles/font" as *;

.flexContainer {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-bottom: 36px;
  @include media-lg-and-up {
    align-items: flex-start;
    flex-direction: row;
  }

  .box {
    padding: 32px 24px;
    border: 1px solid $colour-base-400;
    border-radius: 8px;
  }
  .boxMobile {
    padding: 32px 24px;
    border: 1px solid $colour-base-400;
    border-radius: 8px;
    @include media-lg-and-up {
      padding: 0;
      border: none;
    }
  }
  .quest {
    width: 100%;
    @include media-lg-and-up {
      max-width: 360px;
    }
  }

  .boxOuter {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include media-lg-and-up {
      padding: 32px 24px;
      border: 1px solid $colour-base-400;
      border-radius: 8px;
    }
  }

  .title {
    @extend %text-subtitle-1;
    margin-bottom: 20px;
    color: $colour-primary;
  }

  .subtitle {
    @extend %text-button-md;
    color: $colour-base-800;
    & span {
      color: $colour-secondary;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &Item {
      @extend %text-button-sm;
      display: flex;
      align-items: center;

      gap: 8px;
      color: $colour-base-800;
    }

    &IconBox {
      display:flex;
      justify-content: center;
      align-items: center;
      width: 24px;
    }
  }

  .btn {
    @extend %text-button-md;
    margin-top: 32px;
    font-weight: 600;
  }

  .btnMobile {
    @extend %text-button-md;
    margin-top: 32px;
    font-weight: 600;
    @include media-lg-and-up {
      display: none;
    }
  }

  .btnDesktop {
    @extend %text-button-md;
    display: none;
    font-weight: 600;
    @include media-lg-and-up {
      display: block;
    }
  }
}

//here I have added animation
.successAnimation {
  text-align: center;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.successTitle {
  margin-top: 20px;
  font-size: 24px;
  color: #2e384d;
}

.successSubtitle {
  margin-top: 10px;
  color: #6b7280;
}

.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position: relative;
  margin: 0 auto;
}

.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  border: 2px solid #4bb71b;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.checkmarkStem {
  transform-origin: left top;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  animation: stem 0.3s ease-out 0.9s forwards;
  position: absolute;
  top: 54%;
  left: 28%;
  width: 32%;
  height: 15%;
  background-color: #7fccab;
  transform: rotate(45deg);
}

.checkmarkKick {
  transform-origin: left top;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  animation: kick 0.3s ease-out 0.9s forwards;
  position: absolute;
  top: 73%;
  left: 28%;
  width: 56%;
  height: 15%;
  background-color: #4bb71b;
  transform: rotate(-45deg);
}

.errorContainer {
  text-align: center;
  padding: 40px 0;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

@keyframes stem {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes kick {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}