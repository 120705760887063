@use '../variables' as *;

.schedule__hours {
	width: 100%;
	padding: 32px 0 10px;
	border-bottom: 1px solid rgba($new-gray-color, .25);
	&-top {
		width: 100%;
		justify-content: space-between;
		align-items: flex-start;
		display: flex;
	}

	&-interval {
		display: flex;
    align-items: center;
    gap: 24px;
	}
}
