@use '../variables' as *;

%common-radio {
	width: 0;
	height: 0;
	opacity: 0;
	visibility: 0;
	display: none;
}

.plans-panel {
	background-color: $light-color;
	border-radius: 12px;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
		rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
	overflow: hidden;

	&__tabs {
		display: flex;
		border-bottom: 1px solid lighten($gray-color, 30%);

		&-item {
			cursor: pointer;
			width: 100%;
			span {
				position: relative;
				display: block;
				width: 100%;
				text-align: center;
				font-weight: 700;
				font-size: 14px;
				padding: 15px 20px;
				color: lighten($gray-color, 10%);

				&:before {
					content: '';
					position: absolute;
					bottom: -1px;
					left: 0;
					display: block;
					width: 100%;
					height: 2px;
					background-color: $success-color;
					opacity: 0;
					transition: opacity 0.24s, color 0.24s;
				}
			}

			input {
				@extend %common-radio;

				&:checked + span {
					color: $success-color;

					&:before {
						opacity: 1;
					}
				}
			}
		}
	}

	&__content {
		padding: 30px 25px 20px;
	}
}

.session {
	&__list {
		border-radius: 9px;
		margin-bottom: 15px;
		border: 1px solid lighten($gray-color, 30%);
		overflow: hidden;

		&-item {
			&:not(&:last-child) {
				border-bottom: 1px solid lighten($gray-color, 30%);
			}
			label {
				display: flex;
				align-items: flex-start;
				padding: 10px;
				cursor: pointer;

				&[data-selected='true'] {
					background-color: rgba($success-color, 0.1);
				}
			}
		}
	}

	&__info {
		padding-left: 0.75em;
		font-weight: 700;
		font-size: 14px;
		line-height: 1em;

		small {
			display: block;
			font-size: 12px;
			font-weight: 400;
			padding-top: 0.5em;
		}
	}

	&__button {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
}

.mentorship {
	position: relative;
	&__tabs {
		display: flex;
		border-radius: 8px;
		border: 1px solid lighten($gray-color, 30%);

		&-item {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 14px;
			padding: 10px 20px;
			cursor: pointer;

			input {
				@extend %common-radio;
			}

			&[data-current='true'] {
				background-color: rgba($success-color, 0.1);
			}

			&:first-child {
				border-right: 1px solid lighten($gray-color, 30%);
			}
		}
	}

	&__plan {
		opacity: 0;
		position: absolute;

		&[data-visible='true'] {
			position: static;
			opacity: 1;
		}

		&-price {
			margin: 0.85em 0 0;
			font-size: 26px;

			small {
				font-size: 0.65em;
			}
		}

		&-description {
			font-size: 14px;
			margin: 0.75em 0 1em;
		}

		&-benefits {
			font-size: 14px;

			&-item {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-bottom: 0.75em;

				&:before {
					content: '\2713';
					display: flex;
          align-items: center;
          justify-content: center;
					width: 20px;
					height: 20px;
          font-size: 14px;
					background-color: $success-color;
					border-radius: 50%;
					color: #fff;
				}
			}
		}

		&-link {
			width: 100%;
			text-align: center;
			margin-top: 1.5em;
		}
	}
}
