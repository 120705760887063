@use "@styles/variables" as *;
@use "@styles/mixins" as *;
@use "@styles/font" as *;

.message {
  @extend %text-caption;
  display: flex;
  justify-content: stretch;

  &[data-variant="message"] {
    justify-content: left;
    & p {
      color: $colour-base-800;
      background-color: $colour-base-200;
    }
  }

  &[data-variant="response"] {
    justify-content: right;
    & p {
      color: $colour-base-200;
      background-color: $colour-primary;
    }
  }

  & > p {
    padding: 12px 24px;
    margin-bottom: 10px;
    border-radius: 12px;
  }
}

.flex {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px 0;
}
