@use '../../variables' as *;

.app-settings{
	.custom-btn--transparent{
		color: $dark-color;
		margin: 0;
	}

	&__btns{
		margin-top: 32px;

		button:first-of-type{
			margin-right: 24px;
		}
	}
}

.app__title{
	margin: 2.5em 0 1.5em;
}

.profile-image {
	position: relative;
	display: block;
	width: 180px;
	height: 180px;
	border-radius: 50%;
	overflow: hidden;

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__input {
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 25%;
		width: 100%;
		border-radius: 0 0 50% 50%;
		background-color: rgba($dark-color, 0.9);
		cursor: pointer;
		opacity: 0.75;
		transition: opacity 0.34s;

		&:hover {
			opacity: 1;
		}

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		&-label {
			width: 15%;
			svg {
				fill: $light-color;
				width: 100%;
			}
		}
	}
}
