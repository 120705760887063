@use '../../../scss/variables' as *;
@use '../../../scss/font' as *;

.pageWrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 32px 0 48px;

	@media screen and (min-width: $xl) {
		gap: 48px;
		padding: 40px 0 60px;
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	justify-content: center;

	h2 {
		@extend %text-heading-3;
		color: $colour-primary;
		margin-bottom: 24px;
	}
}

.wrapper {
  margin-top: 36px;
	:global {
		.container {
			display: grid;
			grid-template-columns: calc(60% - 15px) calc(40% - 15px);
      gap: 30px;
			
			@media screen and (min-width: $xl) {
				grid-template-columns: calc(100% - 347px) 317px;
			}
		}
	}
}

