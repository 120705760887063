@use '../variables' as *;

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	&__container {
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: $light-color;
		border-radius: 10px;
		padding: 48px 15px 25px;
		width: 100%;
		max-width: 1024px;
		max-height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		opacity: 0;
		transform: translate(-50%, -20%);
		transition: transform 0.24s, opacity 0.24s;

		//Remove elements scrollbar
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&--visible {
		opacity: 1;
		visibility: visible;
		pointer-events: unset;

		.modal__container {
			opacity: 1;
			transform: translate(-50%, -50%);
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		background-color: transparent;
		border: none;
		font-size: 24px;
		width: 32px;
		height: 32px;
		color: #333;
		cursor: pointer;
	}

	&__button {
		width: 100%;
		border: none;
		background-color: transparent;
	}
}
