@use "../../../../../scss/variables" as *;
@use "../../../../../scss/mixins" as *;
@use "../../../../../scss/main" as *;
@use "../../../../../scss/font" as *;

.sidebar_wrapper {
  border-radius: 12px;
  border: 2px solid $colour-base-400;
  padding: 12px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.border {
  border-radius: 12px;
  border: 1px solid $colour-base-400;
}

.tabs {
  display: flex;
  padding: 8px 12px;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: $colour-base-200;
}

.tab {
  @extend %text-reset-button, %text-button-lg;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  flex: 1;
  color: $colour-base-800;
  border-radius: 4px;
  background-color: transparent;
}

.tabActive,
.tab:hover {
  color: $colour-secondary;
  cursor: pointer;
}

.tabActive {
  background-color: $colour-base-000;
  box-shadow: 0px 0px 0px 0px rgba(207, 207, 207, 0.35);
}

.submitBtn {
  @extend %text-reset-button, %text-button-md;
  border-radius: 50px;
  height: 49px;
  background-color: $colour-secondary;
  color: $colour-base-000;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 36px;
  width: 100%;

  &:hover {
    background-color: lighten($colour-secondary, 5%);
  }

  &:disabled {
    background-color: rgba($dark-color, 0.5) !important;
    pointer-events: none;
    color: white;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  transition: background-color 0.24s;
  &InnerBox:hover {
    border-radius: 16px;
    background-color: $colour-base-200;
  }
}

.rowInfo {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
}

.infoCell {
  @extend %text-caption;
  color: $colour-base-600;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
}

.modalRows {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
}

.rowDescription {
  @extend %text-caption;
  color: $colour-base-600;
}

.disabled {
  opacity: 0.5;
  &:hover {
    background-color: inherit;
  }
}

.tooltipContainer {
  position: relative;
  display: inline-block;

  .btnDisabeldBox:hover + .tooltip {
    display: block;
    opacity: 1;
  }
}
.tooltip {
  display: none;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $colour-base-800;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: $colour-base-800 transparent transparent transparent;
  }
}

.hidden {
  display: none !important;
}
