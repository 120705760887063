@use "../../../scss/variables" as *;
@use "../../../scss/font" as *;

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid $colour-base-800;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;

  &.light {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid $colour-base-800;
  }

  &.sm {
    width: 30px;
    height: 30px;
    border-width: 4px;
  }

  &.lg {
    width: 90px;
    height: 90px;
    border-width: 12px;
  }

  &.full {
    width: 100%;
    height: 100%;
    border-width: 12px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
