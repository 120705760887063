@use '../variables' as *;

label.field__label{
  display: block;
  padding: 0 5px 8px;
  letter-spacing: .25px;
  color: $dark-color;
}

.field__radio{
  padding-left: 8px;
}