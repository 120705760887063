@use "../../../scss/variables" as *;
@use "../../../scss/font" as *;

.card {
    border: 1px solid $colour-base-400;
    border-radius: 16px;
    background-color: transparent;
    padding: 24px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.selected {
        border-color: $colour-secondary;
    }

    &.selectable {
      cursor: pointer;
    }
}

.mentorProfileReview {
    display: grid;
    grid-template-columns: 64px auto;
    gap: 12px;
    align-items: center;
    margin-bottom: 16px;

    .avatarImage {
        height: 64px;
        width: 64px;
        border-radius: 100%;
        object-fit: cover;
    }
    .nameRow {
        display: grid;
        grid-template-columns: 1fr auto auto;
    }
    .mentorDescription {
        display: grid;
        gap: 4px;

    }
}

.rowTitle {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;

    svg {
        width: 20px;
        height: 20px;
    }
}

.crown {
    color: $colour-secondary;
}

.selected .rowTitle svg {
    color: $colour-secondary;
}

.title {
    @extend %text-subtitle-1;
    color: $colour-primary;
}

.subtitle {
    @extend %text-caption;
    color: $colour-base-800;
    margin: 0;
    text-align: left;
}

.priceRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-wrap: wrap;
    row-gap: 4px;

    strong {
        @extend %text-heading-3;
    color: $colour-primary;
    }

    span {
        @extend %text-button-md;
        color: $colour-base-800;
    }
}

.listRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    h6 {
        @extend %text-button-md;
        color: $colour-primary;
        text-align: left;
        width: 100%;
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        margin: 0;
        padding: 0;
    }

    li {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 14px;
        width: 100%;
    }

    svg {
        margin-top:3px;
        width: 18px;
        height: 18px;
        color: $colour-primary;
        border-radius: 50%;
    }

    span {
        flex: 1;
        @extend %text-caption;
        color: $colour-base-800;
    }
}
.profession {
    @extend %text-caption;
    color: $colour-base-600;
    margin: 0;
    text-align: left;
}
.timeZone {
  @extend %text-caption;
  color: $colour-base-600;
  margin-bottom:12px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }
}