@use 'sass:map';
@use '../../variables' as *;

.app-top-bar {
  position: relative;
  z-index: 11;
  grid-column: 1/-1;
  grid-row: 1/2;
	background-color: $light-color;
	padding-top: 8px;
	padding-bottom: 8px;
	box-shadow: -3px 0 10px rgba($dark-color, 0.2);


	@media screen and (min-width: map-get($breakpoints, 'lg')) {
			grid-column: 2/-1;
	}
}
