.langs {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.item {}

.flag {
    width: auto;
    height: 18px;
    box-shadow: 0 0 10px rgb(0 0 0 / 0.2);
}
