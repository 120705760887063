@use "@styles/variables" as *;
@use "@styles/mixins" as *;
@use "@styles/font" as *;

.gridContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  @include media-xl-and-up {
    grid-template-columns: 325px 1fr;
    gap: 20px;
  }
}

.unreadMsg {
  @extend %text-button-md;
  display: none;
  @media (min-width: $xs) {
    display: block;
    width: 188px;
    padding: 10px 12px;
    border: 1px solid $colour-base-200;
    border-radius: 50px;
    background-color: $colour-base-200;
    text-align: center;
    color: $colour-secondary;
  }
}
