@use '@styles/variables' as *;

.fieldText {
	display: block;
	margin-bottom: 19px;
}

.time {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.fieldText {
		margin-bottom: 0;
	}

	&Controler {
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-size: 14px;
			font-weight: 500;
			line-height: 18px;
      margin: 0 12px;
		}
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		border: 1px solid $new-gray-color;
		background-color: transparent;
		font-size: 28px;
    cursor: pointer;

    svg{
      path{
        stroke: $dark-color;
      }
    }

    &:disabled{
      svg{
        opacity: .5;
      }
    }
	}
}