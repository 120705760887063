@use '../../variables' as *;

%common-status {
	display: block;
	padding: 0.25em 1.5em;
	background-color: $success-color;
	color: #fff;
	text-align: center;
  font-weight: 600;
	border-radius: 1em;
  min-width: max-content;
  width: 100%;
  font-size: 0.85em;
}

.styled-table {
	width: 100%;
  overflow-x: scroll;
  border: 1px solid rgba($gray-color, 0.25);
  border-radius: 7px;
  max-width: max-content;
  
  table{
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 7px;
  }

	td,
	th {
		padding: 1em 0.75em;
		border-top: 1px solid rgba($gray-color, 0.25);
		border-bottom: 1px solid rgba($gray-color, 0.25);
		text-align: left;
	}
  
  th{
    padding: 1em 2.5em 1em .75em;
  }

	&__head {
		font-size: 16px;

    tr{
      background-color: rgba($gray-color, .25);
    }
	}

	&__body {
		td {
			span {
				&[data-type='accepted'] {
					@extend %common-status;
					background-color: $success-color;
				}
				&[data-type='waiting'] {
					@extend %common-status;
					background-color: rgba($gray-color, 0.75);
				}
				&[data-type='rejected'] {
					@extend %common-status;
					background-color: $danger-color;
				}
			}

      a{
        &[data-type='download'] {
					@extend %common-status;
          padding: .75em 1.5em;
          border-radius: 7px;
          background-color: $primary-color;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 12px 25px -12px;
        }
      }
		}
	}
}
