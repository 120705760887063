.blocked-screen {
	position: absolute;
	top: 0;
	left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
	width: 100%;
	height: 100%;
	z-index: 120;
  background-color: rgba(255, 255, 255, .9);
  text-align: center;

	svg {
		min-width: 120px;
		width: 40%;
		max-width: 200px;
    opacity: .8;
	}
}
