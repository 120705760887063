@use "@styles/variables" as *;
@use "@styles/mixins" as *;

.wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($dark-color, 0.4);

  .content {
    position: relative;
    background-color: #fff;
    padding: 24px;
    border-radius: 20px;
    max-height: calc(100vh - 64px);
    overflow-x: hidden;
    overflow-y: auto;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.14));

    @include media-md-and-up {
      padding: 32px;
      max-width: $md;
    }

    svg {
      color: $colour-base-600;
    }
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid $colour-base-400;
    border-radius: 8px;
    background-color: $colour-base-200;
    cursor: pointer;

    @include media-md-and-up {
      top: 32px;
      right: 32px;
    }
  }

  .title {
    color: $colour-primary;
  }
}
