@use '../../scss/variables' as *;

.select {
	height: max-content;
	outline: none;

	.text {
		display: block;
		color: $dark-color;
		font-size: 16px;
		font-weight: 600;
		line-height: 150%;
		margin-bottom: 19px;
	}

	:global {
		.css-b62m3t-container {
			border-radius: 8px;
			border: 1px solid $new-gray-color;
		}

		.css-13cymwt-control {
			color: $dark-color;
			font-size: 16px !important;
			font-style: normal;
			font-weight: 400;
			line-height: 30px;
			height: 50px;
			border-color: transparent !important;
			border-radius: 15px;
			cursor: pointer;
		}

		.css-t3ipsp-control {
			box-shadow: none;
			border-color: transparent;
			font-size: 16px !important;
			border-radius: 15px;
			height: 50px;
			cursor: pointer;

			&:hover {
				border-color: transparent;
			}
		}

		.css-1jqq78o-placeholder {
			line-height: 30px;
		}

		.css-1hb7zxy-IndicatorsContainer {
			> span {
				display: none;
			}
		}

		.css-tj5bde-Svg {
			fill: $dark-color;
		}

		.css-qbdosj-Input {
			color: $dark-color;
			font-size: 16px;

			&::placeholder {
				color: $dark-color;
			}
		}

		.css-1nmdiq5-menu {
			margin-top: -10px;
			padding-top: 10px;
			border-radius: 0 0 15px 15px;
			margin-bottom: 0;
			box-shadow: none;
			border: 1px solid transparent;
			border-top: none;

			.css-1n6sfyn-MenuList {
				> div {					
					line-height: 30px;
					cursor: pointer;

					&:hover {
						background-color: #f0f0f0;
					}

					&.css-tr4s17-option,
					&.css-d7l1ni-option {
						background: #f0f0f0;
					}
				}
			}
		}
	}
}
