@use '../../../../scss/variables' as *;

$hamburger-size: 24px;

.navbarButton {
  height: $hamburger-size;
  width: $hamburger-size;
  position: relative;
  background-color: transparent;
  cursor: pointer;

  span {
    width: $hamburger-size;
    height: 2px;
    background-color: $dark-color;
    display: block;
    position: absolute;
    transition: 0.2s;

    &:nth-child(1) {
      top: 20%;
    }

    &:nth-child(2) {
      top: calc(80% - 2px);
    }
  }

  // &.navbarButtonOpen {
  //   span {
  //     &:nth-child(1) {
  //       transform: rotate(45deg);
  //       top: 50%;
  //     }

  //     &:nth-child(2) {
  //       transform: rotate(-45deg);
  //       top: 50%;
  //     }
  //   }
  // }
}
