@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as *;
@use "../../../scss/main" as *;
@use "../../../scss/font" as *;

.Actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-end;
    padding: 32px 24px;

    @include media-lg-and-up {
        padding: 16px 24px;
    }
}

.Content {
    padding-bottom: 24px;

    @include media-lg-and-up {
        padding: 14px;
    }
}

.section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    @include media-lg-and-up {
        flex-direction: row;
        gap: 32px;
    }

    .colTitle {
        @include media-lg-and-up {
            width: 200px;
        }

        @include media-xl-and-up {
            width: 240px;
        }

        @include media-xxl-and-up {
            width: 280px;
        }
    }

    .colContent {
        flex: 1;
    }
}

.colContent {
    border-radius: 8px;
    @include media-lg-and-up {
        border: 1px solid $colour-base-400;
    }
    .Content + .Actions {
        border-top: 1px solid $colour-base-400;
    }
}

.centeredButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.btn.BtnStyledOutline {
    width: 204px;
    height: 41px;
    padding: 8px 40px;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    color: #FF6A3D;
    border: 1px solid #FF6A3D;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
        background-color: rgba(255, 106, 61, 0.1);
        box-shadow: 0px 4px 10px rgba(255, 106, 61, 0.2);
    }
}

