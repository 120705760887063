@use "../../../scss/variables" as *;
@use "../../../scss/font" as *;

.content {
  display: grid;
  grid-template-columns: calc(100% - 283px) minmax(auto, 257px);
  gap: 20px 26px;
}

.button {
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (min-width: $xl) {
  .content {
    transform: translateY(-70px);
    margin-left: 70px;
  }
}


.mainFullWidth {
  max-width: 780px;
  margin: auto;
  align-items: center;
  justify-content: center;

  > section {
    width: 100%;
  }
}

.sectionPayment {
  display: block;
}