@use '../../variables' as *;
@use '../../mixins' as *;

.conversation {
	display: grid;
  grid-template-columns: auto auto auto;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border-radius: 25px;
	padding: 20px;

	&__header {
		grid-column: 1/2;
		grid-row: 1/2;
		padding-bottom: 36px;

		.app__title {
			margin: 0 0 0.75em;
		}
	}

	.user-photo {
		grid-column: 2/3;
		grid-row: 1/2;
		width: 100px;
		height: 100px;
		justify-self: end;
	}

	&__wrapper {
		grid-column: 1/3;
		grid-row: 2/3;
    height: clamp(300px, 40vh, 40vh);
    overflow-y: scroll;
    border-top: 1px solid rgba($gray-color, 0.25);
	}

	&__content {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		gap: 24px;
		width: 100%;
    padding: 20px 0;
	}

	&__form {
		grid-column: 1/3;
		grid-row: 3/4;
    border-top: 1px solid rgba($gray-color, 0.25);
    padding-top: 20px;

    &-input{
      resize: none;
      width: 100%;
      border-radius: 7px;
      padding: 1em 1em;
      height: 5.1em;
      line-height: 1.1em;
      margin-bottom: 16px;
    }

    &-buttons{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }
	}

	@media screen and (min-width: $md) {
		grid-template-columns: auto minmax(300px, 40vh) auto;
	}
}
