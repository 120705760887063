@use 'src/scss/variables' as *;

.wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.field {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  flex: 1 0 200px;
	width: 100%;
	padding: 20px;
	border-radius: 8px;
	border: 2px solid #e2e9fc;
	cursor: pointer;
	transition: border-color 0.24s;

	svg {
		path {
			fill: $dark-color;
			stroke: none;
			transition: fill 0.24s;
		}
	}

	input {
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;
	}

	span {
		display: block;
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		margin-top: 16px;
		transition: color 0.24s;
	}

	&:has(input:checked) {
		border-color: $primary-color;

		span {
			color: $primary-color;
		}

		svg {
			path {
				fill: $primary-color;
			}
		}
	}
}
