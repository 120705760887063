@use '../../variables' as *;

.card-navigation {
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 200px;
	background-color: $light-color;
	border-radius: 8px;

	&__wrapper{
		padding: 20px;
	}

	&:after,
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		z-index: -1;
		background-color: rgba(0, 0, 0, 0.25);
		filter: blur(10px);
		transform: translateY(7px);
		transition: scale 0.54s;
	}

	&:after {
		z-index: 0;
		box-shadow: inset 5px 3px 19px -5px rgba(0, 0, 0, 0.4);
		border-radius: 8px;
		opacity: 0;
		transition: opacity 0.54s;
		pointer-events: none;
	}

	&:hover {
		&:before {
			scale: 0.8;
		}
		&:after {
			opacity: 1;
		}
	}

	&__link {
		display: block;
		padding: 35px 15px;
	}

	&__title {
		display: block;
		font-size: 18px;
		font-weight: 500;
		color: $dark-color;
		text-align: center;
		text-transform: capitalize;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100px;
		width: 60%;
		margin: 0 auto;
		margin-bottom: 34px;

		svg{
			fill: $primary-color;
		}
	}
}
