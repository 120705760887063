@use '../../variables' as *;

.book-payment {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 80px;

	> * {
		grid-column: 1/-1;
	}

	&__submit {
		&-session-name,
		&-session-submit {
			font-size: 16px;
			color: $gray-color;
			margin: 0.25em 0;
		}

		&-session-price {
			font-size: 36px;
			line-height: 1em;
		}

		&-image {
			margin-top: 36px;
			width: 100%;
			height: $xs;
			max-width: $xs;
			overflow: hidden;
			border-radius: 15px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-button {
			display: flex;
			align-items: center;
			gap: 8px;
			background-color: transparent;
			border: none;
			margin-bottom: 36px;
			font-size: 14px;
			cursor: pointer;

			svg {
				font-size: 1em;
				height: 1.5em;
				width: auto;
			}
		}
	}

	&__form {
		display: block;
		padding: 30px 0;
		box-shadow: rgba(0, 0, 0, 0.25) 0px -20px 20px -20px;

		&-title {
			font-size: 32px;
			margin-bottom: 1em;
		}
	}

	@media screen and (min-width: $md) {
		> * {
			grid-column: span 1;
		}

		&__form {
			min-height: min-content;
			height: 90vh;
			padding: 120px 40px 0;
			box-shadow: rgba(0, 0, 0, 0.25) -20px 0px 20px -20px;
		}
	}
}

.book-success {
	position: relative;
	overflow: hidden;
	text-align: center;
	min-height: max-content;
	padding: 20vh 0;

	&__title {
		margin-bottom: 2em;
	}

	canvas {
		top: 0;
		left: 0;
		z-index: -1 !important;
	}

	&__button{
		max-width: 201px;
		margin: 0 auto;
	}
}

.book-payment-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: start; /* Align items at the start of the container */
}

.book-payment-container,
.book-payment-stripe {
	flex: 1; /* Each takes up an equal amount of space */
	min-width: 0; /* Prevents flex items from overflowing */
	padding: 10px; /* Adjust padding as needed */
}

/* Optional: Hide the interfering span */
.ProductSummary-totalAmount {
	display: none; /* Hide it entirely */
	/* Or use visibility: hidden; if you want to keep its space */
}