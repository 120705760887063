@use "@styles/variables" as *;
@use "@styles/mixins" as *;
@use "@styles/font" as *;

.main {
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include media-md-and-up {
    display: flex;
    gap: 48px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 0;
  }
}

.mainContainer {
  display: flex;
  gap: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description {
  color: #56658f;
  font-size: 14px;
  font-weight: 600;
  line-height: 180%;
}

// Templates

.containerOuter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 73px);
  max-width: 700px;
  padding: 24px 4px;
  overflow: hidden;
  overflow-y: auto;
  margin: auto;

  @include media-md-and-up {
    justify-content: center;
    width: 95%;
    height: fit-content;
    padding: 32px 8px;
    border: 1px solid $colour-base-400;
    border-radius: 8px;
  }

  .title {
    @extend %text-heading-3;
    color: $colour-primary;
    margin-top: 32px;

    @include media-lg-and-up {
      font-size: 28px !important;
    }
  }

  .subtitle {
    @extend %text-button-md;
    color: $colour-base-800;
    margin: 20px 0 32px 0;
  }

  .progressBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .step {
      width: 100%;
      height: 8px;
      background-color: $colour-secondary;
      border-radius: 50px;
      opacity: 0.15;
      &[data-variant="active"] {
        opacity: 1;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-grow: 2;
    width: 100%;
    // overflow: hidden;

    & > div:first-child {
      flex-grow: 2;
      // overflow: hidden;
    }
  }

  //  Initial step

  .imgBox {
    position: relative;
    & img:last-child {
      position: absolute;
      right: 20px;
      top: 160px;
      @media (min-width: 480px) {
        left: 200px;
      }
      @media (min-width: $lg) {
        width: 193px;
        height: 59px;

        right: initial;
        top: 40px;
      }
    }
  }
  .btnBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 32px;

    @include media-md-and-up {
      margin-bottom: 0;
    }

    @include media-lg-and-up {
      flex-direction: row;
      gap: 24px;
    }

    button {
      @extend %text-button-md;
    }
  }
}

//Determine step
.radioBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
}

.legend {
  @extend %text-subtitle-1;
  color: $colour-primary;
  margin-bottom: 24px;
}

.summaryImgBox {
  display: flex;
  justify-content: center;
  padding: 12px 0 16px;
  > img {
    max-width: 90%;
  }
}

.scheduleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: auto;

  svg {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
  &:disabled {
    background-color: transparent !important;
  }
}

.scheduleMsgImgBox {
  display: flex;
  justify-content: center;
  padding: 12px 0 16px;
  flex-direction: column;
  gap: 40px;

  > img {
    max-width: 90%;
  }

  p {
    @extend %text-button-lg;
    color: $colour-base-800;
    text-align: center;
    width: 100%;
  }
}

.errorMessage {
  @extend %text-button-lg;
  color: $colour-error-100;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flex {
  display: flex;
}

// Build steps
.plansWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px 12px;

  @include media-lg-and-up {
    grid-template-columns: 1fr 1fr;
  }

  .containerSchedule {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
    height: 90px;
    max-width: 400px;

    .scheduleSubtitle {
      @extend %text-button-md;
      color: $colour-primary;
    }

    .select {
      height: 54px;
    }

    .box {
      @extend %text-body-2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $colour-base-800;
      gap: 10px;
      height: 50px;
      padding: 8px 12px;
      border: 1px solid $colour-base-400;
      border-radius: 8px;
    }
  }
}

.legendBuild {
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: center;
  }

  .right {
    display: flex;
    flex-direction: row;
    gap: 20px;
    row-gap: 8px;
    align-items: flex-end;
    justify-content: center;

    > * {
      cursor: pointer;
      @extend %text-button-sm;
      color: $colour-primary;
    }
  }

  h6 {
    @extend %text-button-md;
    color: $colour-primary;
  }

  p {
    @extend %text-button-sm;
    color: $colour-base-800;
  }
}

.validMsg {
  @extend %text-button-lg;
  color: $colour-error-100;
}

.planErrors {
  padding: 12px;

  li {
    @extend %text-button-md;
    color: $colour-error-100;
  }
}

.addPlanBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.addSchedule {
  margin: auto;
}
